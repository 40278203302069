@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body {
  height: 100%;
  scrollbar-width: none;
}
body {
  margin: 0;
  body {
    font-family: 'Inter', sans-serif;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: 'Inter', sans-serif;
}
